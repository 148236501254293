import React, { Component, RefObject } from "react";
import "./Home.css";
import ScrollContainer from "react-indiana-drag-scroll";

type Props = {
  data: any;
};

type State = {
  imgToDisplay: number;

  rightArrow: boolean;
  leftArrow: boolean;
};

export default class Home extends Component<Props, State> {
  subToolbar: RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.subToolbar = React.createRef();
  }

  componentDidMount() {
    this.setState({ rightArrow: true });
  }

  listenHorizontalScrollEvent(event: any) {
    if (event.currentTarget?.scrollLeft === 0) {
      this.setState({ leftArrow: false });
    } else {
      this.setState({ leftArrow: true });
    }

    if (
      event.target?.scrollLeft + event.target?.offsetWidth >=
      event.target?.scrollWidth - 20
    ) {
      this.setState({ rightArrow: false });
    } else {
      this.setState({ rightArrow: true });
    }
  }

  displayImg(index: number) {
    this.setState({
      imgToDisplay: index,
    });
  }

  render() {
    return (
      <div>
        <div className={"arrow-scroll background"}></div>
        <div id="home"></div>
        <div className={"toolbar-div padded"}>
          <a href="#home">
            <div className={"logo background"}></div>
          </a>

          <p className={"btn-contact-container"}>
            {" "}
            <a
              href={this.props?.data?.Home?.toolbar?.href._value}
              target="_blank"
              rel="noreferrer"
            >
              <button className={"btn-toolbar dark"}>
                <div className="contact-icon background"></div>
                <div>{this.props?.data?.Home?.toolbar?.contattaci._value}</div>
              </button>
            </a>
            <a
              className="hide-mobile"
              href={window.location.href.includes("test.ekfatt.com")?"https://web-test.ekfatt.com":"https://web.ekfatt.com"}
              target="_blank"
              rel="noreferrer"
            >
              <button className={"btn-toolbar"}>
                <div className="login-icon background"></div>
                <div className="hide-mobile">
                  {this.props?.data?.Home?.toolbar?.accedi?.testo?._value}
                </div>
              </button>
            </a>
          </p>
        </div>

        <div className="sub-toolbar-container padded">
          {this.state?.leftArrow ? (
            <div className="arrow arrow-left background"></div>
          ) : (
            ""
          )}
          <div
            className="sub-toolbar"
            ref={this.subToolbar}
            onScroll={this.listenHorizontalScrollEvent.bind(this)}
          >
            {this.props?.data?.Home?.toolbar?.sub_toolbar?.anchors?.map(
              (item: any) => (
                <a href={item.link?._value}>
                  {item?.label?._value}
                  <div className="a-hover"></div>
                </a>
              )
            )}
          </div>
          {this.state?.rightArrow ? (
            <div className="arrow arrow-right background"></div>
          ) : (
            ""
          )}
        </div>

        <div className={"one-div padded"}>
          <div className={"one"}>
            <p
              className={"presentation-div"}
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.blocco_1_presentazione?.descrizione
                    ._value,
              }}
            />

            <p
              className={"sub-presentation-div"}
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.blocco_1_presentazione
                    ?.descrizione_sotto._value,
              }}
            />
          </div>
        </div>

        <div className={"monthly-preview-img-container"}>
          <div className={"btn-dark-container padded"}>
            <a
              href={this.props?.data?.Home?.toolbar?.href._value}
              target="_blank"
              rel="noreferrer"
            >
              <button className={"btn-dark discover-btn"}>
                {
                  this.props?.data?.Home?.blocco_1_presentazione?.bottone_scopri
                    ._value
                }
              </button>
            </a>
          </div>

          <div id="why"></div>
          <img
            className={"monthly-preview-img"}
            src={this.props?.data?.Home?.preview_img?._value}
          />
          <div className={"preview-div padded"}>
            <div
              className={"trial-title-div"}
              dangerouslySetInnerHTML={{
                __html: this.props?.data?.Home?.blocco_2_prova?.titolo._value,
              }}
            />

            <div className={"trial-description-div"}>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    this.props?.data?.Home?.blocco_2_prova?.descrizione._value,
                }}
              />
            </div>
          </div>
        </div>

        <div className={"two-div padded"}>
          <div className={"why-div"}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.blocco_2_prova?.paragrafo_sinistra
                    ?._value,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.blocco_2_prova?.paragrafo_destra
                    ?._value,
              }}
            />
          </div>

          <div className="btn-container">
            <a
              id="who"
              href={this.props?.data?.Home?.toolbar?.href._value}
              target="_blank"
              rel="noreferrer"
            >
              <button className={"btn-dark contact-btn"}>
                {this.props?.data?.Home?.bottone_contattaci._value}
              </button>
            </a>
          </div>
        </div>

        <div className={"for-div padded"}>
          <div>
            <p className={"div-header-question"}>
              {this.props?.data?.Home?.per_chi?.domanda._value}
            </p>

            <p id={"resp"} className={"regular-font align-left"}>
              {this.props?.data?.Home?.per_chi?.risposta._value}
            </p>
          </div>
        </div>

        <div className={"flex-container list-container padded"}>
          <div id={"imgDestinatari"} className={"list-title"}>
            {this.state?.imgToDisplay === 0 ? (
              <div id="grandi-aziende" className="background"></div>
            ) : this.state?.imgToDisplay === 1 ? (
              <div id="studi-commerciali" className="background"></div>
            ) : (
              <div id="piccole-aziende" className="background"></div>
            )}
          </div>
          <div className="list-content">
            {this.props?.data?.Home?.per_chi?.destinatari._list.map(
              (punto: any, index: number) => {
                return (
                  <div
                    id={"azi" + index}
                    className="list-data"
                    onMouseEnter={() => this.displayImg(index)}
                    onMouseLeave={() => this.displayImg(index)}
                  >
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMTIgNGExIDEgMCAwIDEgMSAxdjZoNmExIDEgMCAwIDEgMCAyaC02djZhMSAxIDAgMCAxLTIgMGwtLjAwMS02SDVhMSAxIDAgMCAxIDAtMmg2VjVhMSAxIDAgMCAxIDEtMXoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+DQo=" />

                    <div>
                      <p className={"destinatario-tiolo-p"}>
                        {punto?.titolo?._value}
                      </p>
                      <p className="regular-font">
                        {punto?.descrizione?._value}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div id="how"></div>
        <div className={"padded how-work-div"}>
          <div className={"div-header-question"}>
            {this.props?.data?.Home?.come_funziona?.titolo._value}
          </div>

          <div id={"howWorkResponse"} className={"regular-font align-left"}>
            {this.props?.data?.Home?.come_funziona?.descrizione._value}
          </div>

          <div className={"how-work-details-div"}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.come_funziona?.paragrafo_sinistra
                    ._value,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.come_funziona?.paragrafo_destra
                    ._value,
              }}
            />
          </div>
        </div>

        <div id="what" className="gallery-container">
          <div className={"title padded"}>
            {this.props?.data?.Home?.software?.titolo._value}
          </div>

          <div className={"software-description-div padded"}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.software?.paragrafo_sinistra._value,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.software?.paragrafo_destra._value,
              }}
            />
          </div>

{          <ScrollContainer className="gallery gallery-mockup">
            {this.props?.data?.Home?.software?.mockup?._list.map(
              (item: any) => (
                <div className={"gallery-item"}>
                  <img src={item?.img?._value} />
                  <p
                    className={"left-title"}
                    dangerouslySetInnerHTML={{ __html: item?.titolo?._value }}
                  />
                  <p
                    className={"left-text"}
                    dangerouslySetInnerHTML={{
                      __html: item?.didascalia?._value,
                    }}
                  />
                </div>
              )
            )}
          </ScrollContainer>}

          <p className={"contact-btn-container"}>
            <a
              id="specs"
              href={this.props?.data?.Home?.toolbar?.href._value}
              target="_blank"
              rel="noreferrer"
            >
              <button id="contactBtn" className={"btn-dark"}>
                {this.props?.data?.Home?.software?.vuoi_saperne_bottone?._value}
              </button>
            </a>
          </p>
        </div>

        <div className={"four-div"}></div>

        <div className={"features-div padded"}>
          <p className={"title"}>
            {" "}
            {this.props?.data?.Home?.caratteristiche?.titolo?._value}{" "}
          </p>

          <div className={"grid"}>
            {this.props?.data?.Home?.caratteristiche?.elenco?._list.map(
              (item: any) => (
                <div className={"feature-div"}>
                  <p className={"number"}>{item.Numero?._value}</p>
                  <p className={"text"}>{item.testo?._value}</p>
                </div>
              )
            )}
          </div>

          <div className={"subfeature-title"}>
            {this.props?.data?.Home?.caratteristiche?.sottotitolo._value}
          </div>

          <div className={"grid_2"}>
            {this.props?.data?.Home?.caratteristiche?.elenco_2?._list.map(
              (item: any) => (
                <div>
                  <img src={item.img?._value} />
                  <p className={"title"}>{item.titolo?._value}</p>
                  <p className={"subtitle"}>{item.sottotitolo?._value}</p>
                </div>
              )
            )}
          </div>
        </div>

        {/*Blocco NEWS*/}
        <div id="news" className="gallery-container">
          <div className={"title padded"}>
            {this.props?.data?.Home?.news?.titolo._value}
          </div>

          <ScrollContainer className="gallery gallery-mockup">
            {this.props?.data?.Home?.news?.lista_news?._list ? Object.keys(this.props?.data?.Home?.news?.lista_news?._list).map(
              (k: any) => (
                <div className={"gallery-item"}>
                  <img src={this.props?.data?.Home?.news?.lista_news?._list[k]?.img?._value} />
                  <div className="gallery-item-text-container">
                    <p
                      className={"titoletto"}
                      dangerouslySetInnerHTML={{
                        __html: this.props?.data?.Home?.news?.lista_news?._list[k]?.titoletto?._value,
                      }}
                    />

                    <p
                      className={"left-title"}
                      dangerouslySetInnerHTML={{ __html: this.props?.data?.Home?.news?.lista_news?._list[k]?.titolo?._value }}
                    />
                    <p
                      className={"sottotitolo"}
                      dangerouslySetInnerHTML={{
                        __html: this.props?.data?.Home?.news?.lista_news?._list[k]?.sottotitolo?._value,
                      }}
                    />
                    <p
                      className={"left-text"}
                      dangerouslySetInnerHTML={{
                        __html: this.props?.data?.Home?.news?.lista_news?._list[k]?.menu?._value,
                      }}
                    />
                    <p
                      className={"left-text"}
                      dangerouslySetInnerHTML={{
                        __html: this.props?.data?.Home?.news?.lista_news?._list[k]?.testo?._value,
                      }}
                    />
                  </div>
                </div>
              )
            ) : ""}
          </ScrollContainer>
        </div>

        {/*Blocco EK, la soluzione Kreosoft*/}
        <div className={"contact-div padded"}>
          <div className={"contact-description-div"}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props?.data?.Home?.contattaci?.paragrafo_sinistra._value,
              }}
            />
            <div>
              <img src={this.props?.data?.Home?.contattaci?.img._value} />
            </div>
          </div>

          <div className="btn-container">
            <a
              href={this.props?.data?.Home?.toolbar?.href._value}
              target="_blank"
              rel="noreferrer"
            >
              <button id="end-contact-btn" className={"btn-dark contact-btn"}>
                {this.props?.data?.Home?.bottone_contattaci._value}
              </button>
            </a>
          </div>
        </div>

        {/*Blocco immagine pre-footer*/}
        <div
          className="background pre-footer-img"
          style={{
            backgroundImage:
              "url(" +
              this.props?.data?.Home?.blocco_immagine_pre_footer?.img?._value +
              ")",
          }}
        />

        {/*Footer*/}
        <footer>
          <div className="padded">
            <div className="background kreosoft-logo">
              {" "}
              <a
                className="a-kreo"
                href="https://www.kreosoft.com"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
              </a>
            </div>

            <div className="section-footer-container client-footer-container">
              <div className="section-footer-column">
                <a
                  href={
                    "mailto:" + this.props?.data?.Home?.Footer?.email?._value
                  }
                >
                  <p>{this.props?.data?.Home?.Footer?.email?._value}</p>
                </a>
                <a
                  href={
                    "tel:" + this.props?.data?.Home?.Footer?.telefono?._value
                  }
                >
                  <p>{this.props?.data?.Home?.Footer?.telefono?._value}</p>
                </a>
                <br></br>
                <br></br>
                <p>Via Consiglio dei Sessanta, 99</p>
                <p>World Trade Center - Torre B</p>
                <p>47891 Dogana, San Marino</p>
                <br></br>
                <br></br>
              </div>
              <div className="section-footer-column">
                <div className="section-footer-container client-footer-container hide-mobile">
                  <div className="section-footer-column">
                    {/*<p>
                      <b>Software</b>
                    </p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    */}
                  </div>
                  <div className="section-footer-column">
                    {/*<p>
                      <br></br>
                    </p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    <p>Lorem ipsum</p>
                    */}
                  </div>
                </div>
              </div>
              <div className="flex-05 section-footer-column ">
                <p>
                  <b>Social</b>
                </p>
                {this.props?.data?.Home?.Footer?.Social?.map((social: any) => {
                  return (
                    <p>
                      <a
                        href={social?.link._value}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {social?.descrizione?._value}
                      </a>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="privacy-box">
              <p>© Kreosoft</p>
              <a href="Informativa servizio.pdf" target="_blank">
                informativa servizio
              </a>
              <a href="Modulo esercizio dei diritti.pdf" target="_blank">
                modulo per l'esercizio dei diritti protezione dei dati personali
              </a>
              <a href="Sintesi novita privacy.pdf" target="_blank">
                sintesi novità privacy
              </a>
              <a
                href="https://www.kreosoft.com/Views/privacy-policy.php"
                rel="noreferrer"
                target="_blank"
              >
                privacy policy
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
