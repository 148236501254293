import React from 'react';
import './App.css';
import Home from "./Home";
import FirebaseService from "./firebase.service";
import firebase from "firebase/app";
// @ts-ignore
import AOS from 'aos';
import "aos/dist/aos.css";
import Splashscreen from './splashscreen/Splashscreen';

interface AppState {
  data: {},
  loaded: boolean
}

class App extends React.Component<{}, AppState> {

    constructor(props:any) {
        super(props);

        AOS.init({
            // initialise with other settings
            duration : 1000
          });
    }

    render() {
        return (
            <div className="App">
                
                <Splashscreen loaded={this.state?.loaded}></Splashscreen>
                <Home data={this.state?.data}></Home>
                  
                
                
            </div>
        );
    }

    componentDidMount() {
        FirebaseService.getAll().on("value", this.onDataChange.bind(this));
      }
    
      componentWillUnmount() {
        FirebaseService.getAll().off("value", this.onDataChange.bind(this));
      }
    
      onDataChange(items: firebase.database.DataSnapshot) {
        let content = items.val();
        this.setState({
          data : content
        });

        setTimeout(()=>{
          this.setState({
            loaded: true
          })
        }, 800);

        console.log(this.state);
      }

}

export default App;
